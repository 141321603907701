<template>
  <div>
    <form @submit.prevent="submit">
      <div class="row">
        <loading-notification :show="loading || verifying" />
        <error-notification :show="assignedError">
          <span>{{ $t('notifications.auth.district_assigned') }}</span>
        </error-notification>
      </div>
      <div class="row">
        <div class="flex xs12 md4">
          <va-select
            v-model="selectedCountry"
            :label="$t('layout.form.countryInput')"
            :options="countriesList"
            :loading="isLoading.countries"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="i18nName"
            searchable
          />
        </div>
        <div class="flex xs12 md4">
          <va-input
            color="info"
            v-model="form.name.value"
            :disabled="loading"
            :label="$t('layout.form.nameInput')"
            :error="!!form.name.errors.length"
            :error-messages="translatedErrors(form.name.errors)"
            @blur="validate('name')"
          >
            <va-popover
              :message="$t('layout.translation')"
              placement="top"
              slot="append"
            >
              <va-button
                flat
                small
                type="button"
                color="primary"
                style="margin-right: 0;"
                icon="entypo entypo-language"
                @click="openTranslator"
              />
            </va-popover>
          </va-input>
        </div>
        <div class="flex xs12 md4">
          <va-input
            color="info"
            :value="$t(form.name.value)"
            :disabled="true"
            :label="$t('layout.form.name_translated_input')"
          />
        </div>
      </div>

      <div class="row">
        <div class="flex xs12 md6">
          <va-select
            v-model="selectedCoordinator"
            :label="$t('districts.inputs.coordinatorInput')"
            :options="coordinatorsList"
            :loading="isLoading.coordinators"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            searchable
          />
        </div>
        <div class="flex xs12 md6">
          <va-input
            color="info"
            v-model="coordinatorEmail"
            :label="$t('districts.inputs.emailCoordinatorInput')"
            disabled
          />
        </div>
      </div>

      <div class="row">
        <div class="flex xs12 md6">
          <va-select
            v-model="selectedManager"
            :label="$t('districts.inputs.managerInput')"
            :options="managersList"
            :loading="isLoading.managers"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            searchable
          />
        </div>
        <div class="flex xs12 md6">
          <va-input
            color="info"
            v-model="managerEmail"
            :label="$t('districts.inputs.emailManagerInput')"
            disabled
          />
        </div>
      </div>

      <div class="row">
        <div class="flex xs12">
          <va-button
            color="primary"
            :disabled="loading || verifying"
          >
            <text-loading
              :loading="loading"
              icon="fa fa-save"
            >
              {{ $t('layout.form.save') }}
            </text-loading>
          </va-button>
        </div>
      </div>
    </form>
    <va-modal
      ref="translatorModal"
      v-model="translatorModal"
      :max-width="'100vw'"
      :title="$t('layout.modals.translations')"
      :hide-default-actions="true"
      :fullscreen="true"
      @cancel="closeTranslatorModal"
    >
      <translator-modal
        :edit="true"
        :path="form.name.value"
        translation-type="both"
        @saved="closeTranslatorModal"
      />
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const TranslatorModal = () => import(/* webpackPrefetch: true */ '@/components/extras/TranslatorModal')

export default {
  name: 'districts-form',
  components: {
    TranslatorModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    district: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    isNew () {
      return this.district.id === 0
    },
    coordinatorEmail () {
      return this.selectedCoordinator ? this.selectedCoordinator.email : null
    },
    managerEmail () {
      return this.selectedManager ? this.selectedManager.email : null
    },
  },
  data () {
    return {
      verifying: false,
      assignedError: false,
      isLoading: {
        countries: false,
        managers: false,
        coordinators: false,
      },
      translatorModal: false,
      countriesList: [],
      selectedCountry: '',
      managersList: [],
      selectedManager: '',
      coordinatorsList: [],
      selectedCoordinator: '',
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  watch: {
    district (val) {
      this.setDistrict(val)
    },
    currentLocale (val) {
      this.validateAll()
    },
    'currentUser.permissions' (val) {
      this.initialData()
    },
    selectedManager (val) {
      this.checkManager(val)
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setDistrict (val) {
      this.setFormData(val)
      if (val.country) {
        this.selectedCountry = val.country
        this.selectedCountry.i18nName = this.$t(this.selectedCountry.name)
        this.getUsersCountryBased(this.selectedCountry)
      }
      if (val.manager) {
        this.selectedManager = val.manager
      }
      if (val.coordinator) {
        this.selectedCoordinator = val.coordinator
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    openTranslator () {
      this.translatorModal = true
    },
    closeTranslatorModal () {
      this.translatorModal = false
    },
    initialData () {
      if (this.district) {
        this.setDistrict(this.district)
      }
      this.asyncFindCountry('')
      this.asyncFindManagers('4')
      this.asyncFindCoordinators('7')
    },
    async asyncFindCountry (query) {
      await this.asyncFind(query, 'countries', 'countries')
      for (const c of this.countriesList) {
        c.i18nName = this.$t(c.name)
      }
    },
    asyncFindManagers (query) {
      this.asyncUsers(query, 'managers', 'users')
    },
    asyncFindCoordinators (query) {
      this.asyncUsers(query, 'coordinators', 'users')
    },
    async asyncUsers (query, type, route) {
      this.isLoading[type] = true

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?group=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    async asyncFind (query, type, route) {
      this.isLoading[type] = true

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    getUsersCountryBased (country) {
      this.asyncFindManagers(4 + '&country=' + country.id)
      this.asyncFindCoordinators(7 + '&country=' + country.id)
      if (this.selectedManager && this.selectedManager.country_id !== country.id) {
        this.selectedManager = null
      }
      if (this.selectedCoordinator && this.selectedCoordinator.country_id !== country.id) {
        this.selectedCoordinator = null
      }
    },
    async checkManager (user) {
      this.verifying = true
      this.district.email = this.selectedManager.email

      let response = false
      try {
        response = await this.$http.get(`users/ownership/${user.id}?regions`)
      } catch (e) {
        this.verifying = false
        return
      }

      this.assignedError = response.data.data
      this.verifying = false

      await this.$nextTick()
      this.validateAll()
    },
    async submit () {
      if (!this.isNew && this.selectedManager === null) {
        this.showToast(this.$t('notifications.auth.manager_needed'), {
          icon: 'fa-times',
          position: 'top-right',
        })
        return
      }

      this.validateAll()
      if (!this.formReady) return

      const district = this.getFormData(this.district)

      if (this.selectedCountry) {
        district.country_id = this.selectedCountry.id
      }
      delete district.country

      if (this.selectedManager) {
        district.manager_id = this.selectedManager.id
        delete district.manager
      }

      if (this.selectedCoordinator) {
        district.coordinator_id = this.selectedCoordinator.id
        delete district.coordinator
      }

      if (district.teams) {
        delete district.teams
      }

      this.assignedError = false
      this.$emit('submit', district)
    },
  },
}
</script>
